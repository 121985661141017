<template>
  <div class="campaingContentUpdatePage" v-loading.fullscreen.lock="savingCampaign || fetchingData">
    <template v-if="!fetchingData">
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <EmailBuilder ref="contentBuilder" :onSaveDraftParent="onSaveDraft" :isJourneyCampaign="false"></EmailBuilder>

      <!-- Step Footer -->
      <CampaignFooter :step="step" v-if="campaign" :campaign="campaign" @onNext="onGoNext" @onPrev="onGoBack" @onSaveDraft="onSaveDraft"></CampaignFooter>
    </template>
  </div>
</template>

<style lang="scss" src="./updateCampaignContent.scss"></style>

<script>
import CampaignSteps from '@/components/campaign/createCampaignSteps';
import CampaignFooter from '@/components/campaign/createCampaignFooter';
import campaignContnetUtil from './updateCampaignContentMethods';
import EmailBuilder from '@/components/campaign/contentBuilder/email/editor/emailEditor';

export default {
  name: 'updateEmailContent',
  props: {},
  components: {
    CampaignSteps,
    CampaignFooter,
    EmailBuilder
  },
  data() {
    return {
      step: 2,
      fetchingData: true,

      campaignId: null,
      campaign: null,
      content: null,

      // targetAudianceStepErrors: [],
      savingCampaign: false

      // hasUnsavedChanges: false
    };
  },
  mixins: [],
  watch: {
    targetAudiance: {
      deep: true,
      handler() {
        this.hasUnsavedChanges = true;
      }
    }
  },
  methods: {
    async fetchContents() {
      await campaignContnetUtil.fetchCampaignContent(this);
    },
    async validateAndSave() {
      await campaignContnetUtil.validateAndSave(this);
    },
    async onGoBack() {
      await campaignContnetUtil.goBack(this);
    },
    async onGoNext() {
      await campaignContnetUtil.onGoNext(this);
    },
    async onSaveDraft() {
      await campaignContnetUtil.saveDraft(this);
    }
  },

  mounted() {
    this.campaignId = this.$route.params.id;
    this.fetchContents();
  }
};
</script>
